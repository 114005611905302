/* eslint-disable no-use-before-define */
import React, { useContext, useEffect, useState } from "react";
import { useLocation, matchPath, useHistory } from "react-router-dom";
import PerfectScrollbar from "react-perfect-scrollbar";
import PropTypes from "prop-types";
import clsx from "clsx";
import PlaylistAddCheckIcon from "@material-ui/icons/PlaylistAddCheck";
import { AuthContext } from "src/context/Auth";

import {
  Box,
  Drawer,
  Hidden,
  List,
  ListSubheader,
  makeStyles,
  Avatar,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
} from "@material-ui/core";
import NavItem from "./NavItem";
import { AssignmentTurnedInRounded, } from "@material-ui/icons";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import { MdDashboard } from "react-icons/md";
import { FaUserAlt } from "react-icons/fa";
import { MdContentPaste } from "react-icons/md";
import { RiSlideshowLine, RiSettings4Line } from "react-icons/ri";
import { BiLogOut } from "react-icons/bi";
import CategoryIcon from "@material-ui/icons/Category";
import BusinessIcon from "@material-ui/icons/Business";
import { GiReceiveMoney } from "react-icons/gi";

import { MdSubscriptions } from "react-icons/md";
import { MdOutlineMovieFilter } from "react-icons/md";
import { FaUsers } from "react-icons/fa6";
const sections = [
  {
    items: [
      {
        title: "Dashboard",
        icon: MdDashboard,
        href: "/dashboard",
      },
      {
        title: "User Management",
        icon: FaUsers,
        href: "/user-management",
      },
      {
        title: "Order Management",
        icon: AssignmentTurnedInRounded,
        href: "/order-management",
      },
      {
        title: "Transaction Management",
        icon: GiReceiveMoney,
        href: "/transaction-management",
      },
      {
        title: "Query Management",
        icon: FaUserAlt,
        href: "/query-management",
      },
      {
        title: "Subscription Management",
        icon: MdSubscriptions,
        href: "/subscription-management",
      },
      {
        title: "Testmonial Management",
        icon: MdContentPaste,
        href: "/testmonial-management",
      },
      {
        title: "Static Content Management",
        icon: MdOutlineMovieFilter,
        href: "/static-management",
      },
      {
        title: "FAQ Management",
        icon: RiSlideshowLine,
        href: "/faq-management",
      },
      {
        title: "My Account",
        icon: RiSettings4Line,
        href: "/my-profile",
      },
    ],
  },
];

function renderNavItems({ items, pathname, depth = 0 }) {
  return (
    <List disablePadding>
      {items.reduce(
        (acc, item) => reduceChildRoutes({ acc, item, pathname, depth }),
        []
      )}
    </List>
  );
}

function reduceChildRoutes({ acc, pathname, item, depth }) {
  const key = item.title + depth;

  if (item.items) {
    const open = matchPath(pathname, {
      path: item.href,
      exact: false,
    });

    acc.push(
      <NavItem
        depth={depth}
        icon={item.icon}
        info={item.info}
        key={key}
        open={Boolean(open)}
        title={item.title}
      >
        {renderNavItems({
          depth: depth + 1,
          pathname,
          items: item.items,
        })}
      </NavItem>
    );
  } else {
    acc.push(
      <NavItem
        depth={depth}
        href={item.href}
        icon={item.icon}
        info={item.info}
        key={key}
        title={item.title}
      />
    );
  }

  return acc;
}

const useStyles = makeStyles((theme) => ({
  mobileDrawer: {
    width: 256,
    background: "#fff",
    backdropFilter: "blur(44px)",
  },
  desktopDrawer: {
    width: 256,
    top: 0,
    height: "100%",
    background: "#fff",
    backdropFilter: "blur(44px)",
    boxShadow: "rgb(90 114 123 / 11%) 0px 7px 30px 0px",
  },
  avatar: {
    cursor: "pointer",
    width: 64,
    height: 64,
  },
  socialIcon: {
    cursor: "pointer",
    marginRight: 5,
  },
  logoicon: {
    display: "flex",
    marginTop: "16px",
    alignItems: "center",
    marginLeft: "30px",
  },

  large: {
    width: theme.spacing(10),
    height: theme.spacing(10),
  },
  logoutButton: {
    textAlign: "left",
    display: "flex",
    justifyContent: "flex-start",
    color: "#000",

    "&:hover": {
      color: "#fff",
      background: "#FF860D !important",
      borderRadius: "none !important",
    },
  },
  itemLeaf: {
    display: "flex",
    paddingTop: 0,
    paddingBottom: 0,
    marginTop: "2px",
  },
  buttonLeaf: {
    color: "#f5f7fac9",
    padding: "11px 8px",
    justifyContent: "flex-start",
    textTransform: "none",
    letterSpacing: 0,
    borderLeft: "solid 8px transparent",
    borderRadius: 0,
    fontSize: "13px",
    borderLeft: "3px solid transparent",
    "&:hover": {
      background: "rgb(90 134 255)",
      color: "#fff",
    },

    "&.depth-0": {
      "& $title": {
        fontWeight: theme.typography.fontWeightMedium,
      },
    },
  },
  active: {
    borderLeft: "3px solid #fff",

    fontWeight: theme.typography.fontWeightRegular,
    background: "#6DA9D2",
    "& $title": {
      fontWeight: theme.typography.fontWeightMedium,
    },
    "& $icon": {
      color: "00e0b0",
    },
  },
}));

const NavBar = ({ onMobileClose, openMobile }) => {
  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();
  const [colorbg, setColor] = useState();
  const [selectedIndex, setSelectedIndex] = React.useState(1);
  const auth = useContext(AuthContext);
  const [open1, setOpen1] = React.useState(false);

  const handleClick = (event, index) => {
    setOpen1(!open1);
    setSelectedIndex(index);
  };
  const [isLogout, setIsLogout] = useState(false);
  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  const content = (
    <Box
      height="100%"
      display="flex"
      flexDirection="column"
      className="example"
    >
      <PerfectScrollbar options={{ suppressScrollX: true }}>
        <Box
          mt={2}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            cursor: "pointer",
          }}
        >
          <Avatar
            onClick={() => history.push("/my-profile")}
            className={classes.large}
            src={auth?.userData?.profilePic}
          />
        </Box>
        <Box style={{
          padding: "10px",
          textAlign: "center",
          overflowWrap: "break-word"
        }}>
          <Typography variant="h6" style={{ color: "#000" }}>
            {auth?.userData?.name}
          </Typography>
        </Box>
        <Box pt={1}>
          {sections.map((section, i) => (
            <List
              key={`menu${i}`}
              subheader={
                <ListSubheader disableGutters disableSticky>
                  {section.subheader}
                </ListSubheader>
              }
            >
              {renderNavItems({
                items: section.items,
                pathname: location.pathname,
              })}
            </List>
          ))}
        </Box>
        <Button style={{ textTransform: "none" }}
          fullWidth
          onClick={() => setIsLogout(true)}
          className={classes.logoutButton}
        >
          <ExitToAppIcon style={{ marginLeft: "13px" }} />
          &nbsp; &nbsp; Logout
        </Button>
      </PerfectScrollbar>

      {isLogout && (
        <Dialog open={isLogout} keepMounted onClose={() => setIsLogout(false)}>
          <DialogContent>
            <Box className={classes.dialougTitle}>
              <Typography
                variants="h2"
                style={{ fontSize: "20px", color: "#000" }}
              >
                Are you sure want to logout?
              </Typography>
            </Box>
          </DialogContent>
          <DialogActions
            style={{
              alignContent: "center",
              justifyContent: "center",
            }}
          >
            <Box>
              <Button
                color="primary"
                variant="contained"
                onClick={() => setIsLogout(false)}
              >
                No
              </Button>
              &nbsp;
              <Button
                color="primary"
                variant="contained"
                onClick={() => {
                  history.push("/");
                  sessionStorage.clear();
                  auth.userLogIn(false, null);
                }}
              >
                Yes
              </Button>
            </Box>
          </DialogActions>
        </Dialog>
      )}
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          anchor="left"
          classes={{ paper: classes.desktopDrawer }}
          open
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
};

export default NavBar;
