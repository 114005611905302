export const baseurl = process.env.REACT_APP_API_BASE_URL
// export const baseurl = "http://localhost:2066/api/v1"
let admin = `${baseurl}/admin`;
let subscription = `${baseurl}/subscription`;
let testmonial = `${baseurl}/testmonial`;
let contact = `${baseurl}/contact`;
let staticContent = `${baseurl}/static`;
let order = `${baseurl}/order`;

const Apiconfigs = {
  // admin Login
  login: `${admin}/login`,
  getProfile: `${admin}/profile`,
  editProfile: `${admin}/editProfile`,
  forgotPassword: `${admin}/forgotPassword`,
  changePassword: `${admin}/changePassword`,
  verifyOTP: `${admin}/verifyOTP`,
  resetPassword: `${admin}/resetPassword`,
  addCompanyDetail: `${admin}/addCompanyDetails`,
  activeBlockUser: `${admin}/activeBlockUser`,
  xlsxDownload: `${admin}/xlsxDownload`,
  dashboard: `${admin}/adminDashboard`,

  //users
  getallusers: `${admin}/getallusers`,
  contactUsList: `${contact}/contactUsList`,

  //subscription
  listTransaction: `${subscription}/listTransaction`,
  listSubscription: `${subscription}/listSubscription`,
  editSubscription: `${subscription}/editSubscription`,

  //staticContent
  staticContentList: `${staticContent}/listStaticContent`,
  editStaticContent: `${staticContent}/editStaticContent`,
  faqList: `${staticContent}/faqList`,
  addFaq: `${staticContent}/addFaq`,
  viewFaq: `${staticContent}/viewFaq`,
  editFaq: `${staticContent}/editFaq`,
  deleteFaq: `${staticContent}/deleteFaq`,

  //testmonial
  listTestmonial: `${testmonial}/listTestmonial`,
  deleteTestmonial: `${testmonial}/deleteTestmonial`,
  addTestmonial: `${testmonial}/addTestmonial`,
  viewTestmonial: `${testmonial}/viewTestmonial`,
  editTestmonial: `${testmonial}/editTestmonial`,
  activeBlockTestmonial: `${testmonial}/activeBlockTestmonial`,

  //order
  orderList: `${order}/orderList`,
  orderAdminList: `${order}/orderAdminList`,
  updateDeliveryStatus: `${order}/updateDeliveryStatus`,


};

export default Apiconfigs;
