import React, { createContext, useState, useEffect } from "react";
import axios from "axios";
import apiConfig from "src/ApiConfig/ApiConfig";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import Cookies from "js-cookie";

export const AuthContext = createContext();

const setSession = (accessToken) => {
  if (accessToken) {
    sessionStorage.setItem("token", accessToken);
    axios.defaults.headers.common.Token = `${accessToken}`;
  } else {
    sessionStorage.removeItem("token");
    delete axios.defaults.headers.common.Token;
  }
};

function checkLogin() {
  const accessToken = window.sessionStorage.getItem("token");
  return accessToken ? true : false;
}

export default function AuthProvider(props) {
  const [isLogin, setIsLogin] = useState(checkLogin());
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [userData, setUserData] = useState({});
  const [userAccessData, setUserAccessData] = useState([]);
  console.log("dfdsfd",userAccessData)
  const [searchToken, setSearchToken] = useState("");
  const [theme, setTheme] = useState(true);
  const token = window.sessionStorage.getItem("token");
  const [Role, setRole] = useState("")
  const getProfileHandler = async () => {
    try {
      const res = await axios({
        method: "GET",
        url: apiConfig.getProfile,
      });
      if (res.data.responseCode === 200) {
        setUserData(res.data.result);
        setRole(res.data.result?.userType)
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (token) {
      setSession(token)
      getProfileHandler();
    }
  }, [token]);



  let data = {
    userLoggedIn: isLogin,
    userData,
    searchToken,
    Role,
    userAccessData,
    isLoading,
    setRole,
    setIsLoading: (data) => setIsLoading(data),
    theme,
    setTheme: (data) => setTheme(data),
    setSearchToken: (data) => setSearchToken(data),
    userLogIn: (type, data) => {
      setSession(data);
      setIsLogin(type);
    },
    getProfileHandler: (data) => getProfileHandler(data),
  };

  return (
    <AuthContext.Provider value={data}>{props.children}</AuthContext.Provider>
  );
}