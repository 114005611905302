import React, { lazy } from "react";
import { Redirect } from "react-router-dom";
import HomeLayout from "src/layouts/HomeLayout";
import DashboardLayout from "src/layouts/DashboardLayout";
import LoginLayout from "src/layouts/LoginLayout";

export const routes = [
  {
    exact: true,
    path: "/",
    layout: LoginLayout,
    component: lazy(() => import("src/views/pages/Home/auth/Login/index")),
  },

  {
    exact: true,
    path: "/forget-password",
    layout: LoginLayout,

    component: lazy(() =>
      import("src/views/pages/Home/auth/forget-password/index")
    ),
  },

  {
    exact: true,
    path: "/reset-password",
    layout: LoginLayout,

    component: lazy(() =>
      import("src/views/pages/Home/auth/reset-password/index")
    ),
  },
  {
    exact: true,
    path: "/verify-otp",
    layout: HomeLayout,

    component: lazy(() =>
      import("src/views/pages/Home/auth/Verify-Otp/VerifyOtp")
    ),
  },
  {
    exact: true,
    path: "/dashboard",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/Dashboard")),
  },
  {
    exact: true,
    path: "/view-static-screen",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/StaticContentManagement/ViewStaticScreen")
    ),
  },
  {
    exact: true,
    guard: true,
    path: "/query-management",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/ContactusManagement/ContactUsList")
    ),
  },
  {
    exact: true,
    guard: true,
    path: "/query-management/view-details",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/ContactusManagement/ViewContactUs")
    ),
  },
  {
    exact: true,
    guard: true,
    path: "/subscription-management",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/SubscriptionManagement/SubscriptionList")
    ),
  },
  {
    exact: true,
    guard: true,
    path: "/subscription-management/view-details",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/SubscriptionManagement/ViewSubscription")
    ),
  },
  {
    exact: true,
    guard: true,
    path: "/subscription-management/add-details",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/SubscriptionManagement/AddSubscription")
    ),
  },


  {
    exact: true,
    guard: true,
    path: "/testmonial-management",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/TestimonialManagement/TestmonialManagement")
    ),
  },
  {
    exact: true,
    guard: true,
    path: "/testmonial-management/view-details",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/TestimonialManagement/ViewTestmonial")
    ),
  },
  {
    exact: true,
    guard: true,
    path: "/testmonial-management/add-details",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/TestimonialManagement/AddTestmonial")
    ),
  },


  {
    exact: true,
    path: "/edit-static-screen",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/StaticContentManagement/EditStaticScreen")
    ),
  },
  {
    exact: true,
    path: "/edit-static-feature-screen",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/StaticContentManagement/EditStaticFeatureScreen")
    ),
  },
  {
    exact: true,
    path: "/edit-static-video-screen",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/StaticContentManagement/EditBannerVideoManagement")
    ),
  },
  {
    exact: true,
    path: "/view-static-video-screen",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/StaticContentManagement/ViewBannerVideoManagement")
    ),
  },
  {
    exact: true,
    path: "/edit-static-Link-screen",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/StaticContentManagement/EditStaticLink")
    ),
  },
  {
    exact: true,
    path: "/view-static-Link-screen",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/StaticContentManagement/ViewStaticLinkScreen")
    ),
  },

  {
    exact: true,
    path: "/static-management",
    layout: DashboardLayout,
    component: lazy(() =>
      import(
        "src/views/pages/Dashboard/StaticContentManagement/StaticManagement"
      )
    ),
  },
  {
    exact: true,
    path: "/edit-static-home-screen",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/StaticContentManagement/EditStaticHomeScreen")
    ),
  },
  {
    exact: true,
    path: "/user-management",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/UserManagement/UserManagement")
    ),
  },
  {
    exact: true,
    path: "/user-view",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/UserManagement/ViewUser")
    ),
  },
  {
    exact: true,
    path: "/add-user",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/UserManagement/AddUser.js")
    ),
  },
  {
    exact: true,
    path: "/edit-user",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/UserManagement/EditUser")
    ),
  },
  {
    exact: true,
    path: "/my-profile",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/Settings/Settings")
    ),
  },
  {
    exact: true,
    path: "/change-password",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/Settings/Changepassword")
    ),
  },

  // -------------admin------------------
  {
    exact: true,
    path: "/transaction-management",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/TransactionManagement/TransactionManagement")
    ),
  },
  {
    exact: true,
    path: "/order-management",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/OrderManagement/OrderManagement")
    ),
  },
  {
    exact: true,
    guard: true,
    path: "/order-management/view-order",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/OrderManagement/ViewOrder")
    ),
  },
  {
    exact: true,
    path: "/faq-management",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/FaqManagement/FaqList")
    ),
  },
  {
    exact: true,
    path: "/add-faq",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/FaqManagement/AddFaq")
    ),
  },
  {
    exact: true,
    path: "/view-faq",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/FaqManagement/ViewFaq")
    ),
  },
  {
    exact: true,
    path: "/update-faq",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/FaqManagement/UpdateFaq")
    ),
  },
  {
    exact: true,
    path: "/404",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/errors/NotFound")),
  },
  {
    component: () => <Redirect to="/404" />,
  },
];
